import React, { useState, useRef  } from "react";
import { useDispatch } from "react-redux";
import {Link, useNavigate, useLocation } from 'react-router-dom';
//import { createPage } from "../slices/posts";
import { createPage } from "../../_store/pages";
//import ReactQuill from "react-quill";
//import "react-quill/dist/quill.snow.css";
import RichTextEditor from '../../_helpers/RichTextEditor';
import "../style.scss";
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { Box } from '@mui/material';
import Header from "../../components/Header";
import slugify from 'react-slugify';
import axios from "axios";
//import { Formik, Field, Form, ErrorMessage } from 'formik';

const AddPage = () => {
	 const state = useLocation().state;
  const initialPageState = {
    id: null,
    title: "",
    description: "",
    img:"",
    published: false
  };
	  const navigate = useNavigate();
  const [value, setValue] = useState("");
    const [imagePreviews, setImagePreviews] = useState([]);
  const [Page, setPage] = useState(initialPageState);
  const [submitted, setSubmitted] = useState(false);
   const [file, setFile] = useState(null);
 const [pimg, setPimg] = useState(state?.img || "");
  const filesharhe_ref = useRef();
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios.post(process.env.REACT_APP_API_URL + "/test/upload", formData);
      // setPimg(res.data.message]);
      return res.data;
    } catch (err) {
      //console.log(err);
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT
      });
    }
  };
async function loadimg() {

    //  setFile(fis);
    if (file) {

console.log("load",file.name)
      // await setFile(file);
setPimg(file.name);
      const imgUrl = await upload();
   //   //window.alert(JSON.stringify({ imgUrl }));
      //  setPimg(imgUrl.message);
      //  	  //window.alert(imgUrl.message);
      //  //window.alert(pimg);
      //  fields["img"]=pimg;
    };

  }

  const handleClick = async (e) => {
    e.preventDefault();
   await setFile(e.target.files[0]);
    await setPimg(e.target.files[0].name);
		console.log("File",file, e.target.files[0].name);
	console.log("Pimg",pimg);
   // //window.alert(e.target.files[0].name);
    //  //window.alert(JSON.stringify(e.target.files[0]));
    //  //window.alert(JSON.stringify({file}));
    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    //  setSelectedFiles(event.target.files);
    setImagePreviews(images);
	console.log("Pimg 2",pimg);
   // //window.alert(imagePreviews);
  //  //window.alert(JSON.stringify({ imagePreviews }));
    /* if(file) {
     const imgUrl = await upload();
          setPimg(imgUrl.message);
     // //window.alert(pimg);
     // fields["img"]=pimg;
   };*/
  };
  const dispatch = useDispatch();

  const handleInputChange = event => {
    const { name, value } = event.target;
    setPage({ ...Page, [name]: value });
  };
  const handleContentChange = contentHtml => {
	setValue(contentHtml);
			    };
  const savePage = () => {
    //  const { title, description } = Page;
	loadimg();
    const title = Page.title;
    const slug = slugify(title);
    const description = value;
	const img = pimg;
	console.log("img",img);
    dispatch(createPage({ title, description, img, slug }))
      .unwrap()
      .then(data => {
        //console.log(data);
        setPage({
          id: data.id,
          title: data.title,
          description: data.description,
		  img: data.img,
          published: data.published
        });
        setSubmitted(true);
		toast.success("Pagina a fost adaugata cu succes!");
		navigate("/admin/pages");
      })
      .catch(e => {
        //console.log(e);
		toast.error(e)
      });
  };

  const newPage = () => {
    setPage(initialPageState);
    setValue("");
    setSubmitted(false);
  };

  return (
   <Box m="20px">
    <div className="submit-form">
      {submitted ? (
        <div>
          <h4>Ați trimis cu succes!</h4>
          <button className="btn btn-success" onClick={newPage}>
            Adauga
          </button>
        </div>
      ) : (
        <div>
          <div className="form-group">
            <label htmlFor="title">Titlu</label>
            <input
              type="text"
              className="form-control"
              id="title"
              required
              value={Page.title || ''}
              onChange={handleInputChange}
              name="title"
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Descriere</label>


		   <RichTextEditor
            content={value}
            handleContentChange={handleContentChange}
            placeholder="introduceți text aici..."
            />
          </div>

<div className="form-group">
                <label></label>
		  {/*
                <Field name="img" type="hidden"  value={pimg} className={'form-control'} />
                <ErrorMessage name="img" component="div" className="invalid-feedback" /> */}


                <label className="file btn btn-sm btn-info" htmlFor="file">Incarca imaginea</label>

		    <input innerref={filesharhe_ref} type="file" id="file" name="myfile" onChange={handleClick}
                  accept=".jpg, .png, .gif, .svg, .webp"/>
		  {/*  <ErrorMessage name='myfile'  /> */}
                {pimg && (!file) && (
                  <img src={process.env.REACT_APP_API_URL + "/test/files/" + pimg} alt={pimg} height="80px" align="center" className='img-fluid' />

                )}

                {imagePreviews && (
                  <>
                    {imagePreviews.map((img, i) => {
                      return (
                        <img src={img} alt={"image-" + i} key={i} height="80px" align="center" className='img-fluid' />
                      );
                    })}
                  </>
                )}

              </div>

          <button onClick={savePage} className="btn btn-success">
            Trimite
          </button>
			   <Link to='/admin/pages' className="btn btn-link">Abandon</Link>
        </div>
      )}
    </div>
 </Box>
  );
};

//export default AddPage;
export { AddPage };
