import React from 'react';
import './image.css';
import { toast } from 'react-toastify';




const Imgligth = ({img}) => {
  return (


                                        <div className="vh-80 vw-80 imgf" style={{alignItems:'center', justifyContent:'center'}} >
                                            <img src={`${img}`} alt="img" className="img-fluid" align="middle" />
                                        </div>

                                    
    )
}
export default Imgligth;
