import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PageDataService from "../_services/PageDataService";

const initialState = [];

export const createPage = createAsyncThunk(
  "Pages/create",
  async ({ title, description,img, slug }) => {
    const res = await PageDataService.create({ title, description,img, slug });
    return res.data;
  }
);

export const retrievePages = createAsyncThunk(
  "Pages/retrieve",
  async () => {
    const res = await PageDataService.getAll();
    return res.data;
  }
);

export const updatePage = createAsyncThunk(
  "Pages/update",
  async ({ id, data }) => {
    const res = await PageDataService.update(id, data);
    return res.data;
  }
);

export const deletePage = createAsyncThunk(
  "Pages/delete",
  async (id ) => {
    const res = await PageDataService.remove(id);
    return res.data;
  }
);

export const deleteAllPages = createAsyncThunk(
  "Pages/deleteAll",
  async () => {
    const res = await PageDataService.removeAll();
    return res.data;
  }
);

export const findPagesByTitle = createAsyncThunk(
  "Pages/findByTitle",
  async ({ title }) => {
    const res = await PageDataService.findByTitle(title);
    return res.data;
  }
);

const PageSlice = createSlice({
  name: "Page",
  initialState,
  extraReducers: {
    [createPage.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [retrievePages.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [updatePage.fulfilled]: (state, action) => {
      const index = state.findIndex(Page => Page.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
    },
    [deletePage.fulfilled]: (state, action) => {
      let index = state.findIndex(({ id }) => id === action.payload.id);
      state.splice(index, 1);
    },
    [deleteAllPages.fulfilled]: (state, action) => {
      return [];
    },
    [findPagesByTitle.fulfilled]: (state, action) => {
      return [...action.payload];
    },
  },
});

const { reducer } = PageSlice;
export default reducer;