import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { updatePage, deletePage } from "../../_store/pages";
//import PageService from "../services/PageService";
import { PageService } from '../../_services/PageService';
import RichTextEditor from '../../_helpers/RichTextEditor';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../style.scss";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'react-slugify';
import { Box } from '@mui/material';
import UploadService from "../../_services/FileUploadService";


const Page = (props) => {
  const { id } = useParams();
  const state = useLocation().state;
  const navigate = useNavigate();
  const [value, setValue] = useState(state?.description || "");
    const [file, setFile] = useState(null);
  const [pimg, setPimg] = useState("");
  const initialPageState = {
    id: null,
    title: "",
    description: "",
    img: "",
    published: false
  };
  const [currentPage, setCurrentPage] = useState(initialPageState);
  const [message, setMessage] = useState("");
   const [imagePreviews, setImagePreviews] = useState([]);

 const filesharhe_ref = useRef();
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await UploadService.upload(file);
      // setPimg(res.data.message]);
      //console.log(res.data.message);
      return res.data;
    } catch (err) {
      //console.log(err);
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT
      });
    }
  };

  async function loadimg() {

    if (file) {
		console.log("load",file.name)
      // await setFile(file);
setPimg(file.name);
      const imgUrl = await upload();
    };

  }

  const handleClick = async (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setPimg(e.target.files[0].name);
    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    //  setSelectedFiles(event.target.files);
    setImagePreviews(images);
   // //window.alert(imagePreviews);
  //  //window.alert(JSON.stringify({ imagePreviews }));
    /* if(file) {
     const imgUrl = await upload();
          setPimg(imgUrl.message);
     // //window.alert(pimg);
     // fields["img"]=pimg;
   };*/
  };


  const dispatch = useDispatch();

  const getPage = id => {
    PageService.get(id)
      .then(response => {
        setCurrentPage(response.data);
		setPimg(currentPage.img);
		console.log("currentPage : ",currentPage);
        //setValue(currentPage.description);
        ////window.alert(currentPage.description);
      })
      .catch(e => {
        //console.log(e);
      });
  };

  useEffect(() => {
    if (id)
      getPage(id);
	setPimg(currentPage.img);
	console.log("Pimg",pimg, currentPage.img);
  }, [id]);

const   handleContentChange = contentHtml => {
	setValue(contentHtml);

			    };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentPage({ ...currentPage, [name]: value });
  };

  const handleEditorChange = (value) => {
    // const { name, value } = event.target;
    setCurrentPage({ ...currentPage, description: value });
  };

  const updateStatus = status => {
	 // loadimg();
	 // console.log("Pimg 3", pimg);
    const data = {
      id: currentPage.id,
      title: currentPage.title,
      description: value,
      published: status,
      slug: slugify(currentPage.title)
    };

    dispatch(updatePage({ id: currentPage.id, data }))
      .unwrap()
      .then(response => {
        //console.log(response);
        setCurrentPage({ ...currentPage, published: status });
		getPage(id);
        setMessage("Starea a fost actualizată cu succes!");
        toast.success("Starea a fost actualizată cu succes!", {
          position: toast.POSITION.TOP_CENTER
        });
      })
      .catch(e => {
		  toast.error(e);
        console.log(e);
      });
  };

  const updateContent = () => {
    // setCurrentPage({ ...currentPage, description: value });
	loadimg();
	console.log("Pimg 2", pimg);
    const data = {
      id: currentPage.id,
      title: currentPage.title,
      description: value,
      img:pimg,
      published: currentPage.published,
      slug: slugify(currentPage.title)
    };
    ////window.alert(currentPage.description);     dispatch(updatePage({ id: currentPage.id, data: currentPage }))
    dispatch(updatePage({ id: currentPage.id, data }))
      .unwrap()
      .then(response => {
        //console.log(response);
        setMessage("Pagina a fost actualizată cu succes!");
        toast.success("Pagina a fost actualizată cu succes!", {
          position: toast.POSITION.TOP_CENTER
        });
		          navigate("/admin/pages");
      })
      .catch(e => {
        //console.log(e);
		toast.error(e);
      });
  };

  const removePage = () => {
	 if (window.confirm("Stergi pagina? " + currentPage.title)) {
    dispatch(deletePage({ id: currentPage.id }))
      .unwrap()
      .then(() => {
        navigate("/admin/pages");
      })
      .catch(e => {
        //console.log(e);
      });
		  };
  };

  return (
  <Box m="20px">
    <div>
      {currentPage ? (
        <div className="edit-form">
          <h4>Pagina</h4>
          <form>
            <div className="form-group">
              <label htmlFor="title">Titlu</label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={currentPage.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Continut</label>
              <input
                type="hidden"
                className="form-control"
                id="description"
                name="description"
                value={value}
                onChange={handleInputChange}
              />

		   <RichTextEditor
            content={value}
            handleContentChange={handleContentChange}
            placeholder="introduceți text aici..."
            />
            </div>

<div className="form-group">
                <label></label>
		  {/*
                <Field name="img" type="hidden"  value={pimg} className={'form-control'} />
                <ErrorMessage name="img" component="div" className="invalid-feedback" /> */}


                <label className="file btn btn-sm btn-info" htmlFor="file">Incarca imaginea</label>

		    <input innerref={filesharhe_ref} type="file" id="file" name="myfile" onChange={handleClick}
                  accept=".jpg, .png, .gif, .svg, .webp"/>
		  {/*  <ErrorMessage name='myfile'  /> */}
                {currentPage.img && (!file) && (
                  <img src={process.env.REACT_APP_API_URL + "/test/files/" + currentPage.img} alt={currentPage.img} height="80px" align="center" className='img-fluid' />

                )}

                {imagePreviews && (
                  <>
                    {imagePreviews.map((img, i) => {
                      return (
                        <img src={img} alt={"image-" + i} key={i} height="80px" align="center" className='img-fluid' />
                      );
                    })}
                  </>
                )}

              </div>

            <div className="form-group">
              <label>
                <strong>Seo:</strong>
              </label>
              {currentPage.slug}
            </div>
            <div className="form-group">
              <label>
                <strong>Status:</strong>
              </label>
              {currentPage.published ? "Publicat" : "Draft"}
            </div>
          </form>

          {currentPage.published ? (
            <button
              className="badge badge-primary mr-2"
              onClick={() => updateStatus(false)}
            >
              Draft
            </button>
          ) : (
            <button
              className="badge badge-primary mr-2"
              onClick={() => updateStatus(true)}
            >
              Publica
            </button>
          )}

          <button className="badge badge-danger mr-2" onClick={removePage}>
            Sterge
          </button>

          <button
            type="submit"
            className="badge badge-success"
            onClick={updateContent}
          >
            Modifica
          </button>
			  <Link to='/admin/pages' className="btn btn-link">Abandon</Link>
          <p>{message}</p>
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a Page...</p>
        </div>
      )}
    </div>
 </Box>
  );
};

//export default Page;
export { Page };