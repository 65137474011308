import React from "react";
import { tokens } from "../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";


//export { AuthLayout };https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg

function AuthLayout({ children }) {


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

 return ( <>
            <section className="vh-100 vw-100 bg-light bg-gradient"  style={{backgroundImage: `url("back.webp")`}}>
                <div className="container-fluid h-custom" >
                    <div className="row d-flex justify-content-center align-items-center h-100">
	{children}       
                    </div>
                </div>
            </section>
        </>  
			)
}

export default AuthLayout;